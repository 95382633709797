<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>Registration Management</v-toolbar-title>
      <v-spacer />
      <v-form @submit.stop.prevent="fetchData">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        />
      </v-form>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors" />
      <p>
        The following list of registrants require approval before they can login to the system. Upon approval,
        registrants will receive a welcome email. Upon rejection, registrants will receive an email with a reason.
      </p>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        v-if="this.items"
      >
        <template v-slot:item.createdDate="{ item }">
          <span> {{ item.createdDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span>
        </template>
        <template v-slot:item.organization.name="{ item }">
          <div>
            {{ item.customFieldValue16 }} at ({{ item.organization.legacyOrganizationKey }})
            {{ item.organization.name }}
          </div>
          <div class="pl-5" v-if="item.organization.address && item.organization.address.address1">
            {{ item.organization.address.address1 }}
            {{ item.organization.address.address2 ? item.organization.address.address2 : "" }}
          </div>
          <div class="pl-5">
            {{ item.organization.address ? item.organization.address.cityRegionPostalCountry : "" }}
          </div>
          <div class="pl-5">{{ item.organization.phoneNumber1 | toPhoneNumber }}</div>

          <div class="mt-5" v-for="relatedOrganization in item.relatedOrganizations" :key="relatedOrganization.id">
            <div>
              {{ relatedOrganization.description }} at ({{
                relatedOrganization.relatedOrganization.legacyOrganizationKey
              }}) {{ relatedOrganization.relatedOrganization.name }}
            </div>
            <div
              class="pl-5"
              v-if="
                relatedOrganization.relatedOrganization.address &&
                  relatedOrganization.relatedOrganization.address.address1
              "
            >
              {{ relatedOrganization.relatedOrganization.address.address1 }}
              {{
                relatedOrganization.relatedOrganization.address.address2
                  ? relatedOrganization.relatedOrganization.address.address2
                  : ""
              }}
            </div>
            <div class="pl-5">
              {{
                relatedOrganization.relatedOrganization.address
                  ? relatedOrganization.relatedOrganization.address.cityRegionPostalCountry
                  : ""
              }}
            </div>
            <div class="pl-5">{{ relatedOrganization.relatedOrganization.phoneNumber1 | toPhoneNumber }}</div>
          </div>
        </template>
        <template v-slot:item.user.firstName="{ item }">
          <div>
            {{ item.fullName }}
          </div>
          <div class="pl-5">{{ item.phoneNumber1 | toPhoneNumber }}</div>
          <div class="pl-5">{{ item.email1 }}</div>
        </template>
        <template v-slot:item.approve="{ item }">
          <v-btn class="ma-2" text icon color="primary lighten-2" @click="openApproveDialog(item)">
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.reject="{ item }">
          <v-btn class="ma-2" text icon color="warning lighten-2" @click="openRejectDialog(item)">
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="approveDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">Approve Registration?</v-card-title>
          <v-card-text>
            <p>
              Are you sure you'd like to approve the registration for
              <b>{{ itemSelected.fullName }}</b
              >? A welcome email will be sent after approval.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="close">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" @click="approve" :loading="loadingApprove">Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="600px">
        <v-form @submit.prevent="reject" v-model="reasonValid">
          <v-card>
            <v-card-title>
              <span class="headline"> Reject Registration? </span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p>
                    Are you sure you'd like to reject the registration for <b>{{ itemSelected.fullName }}</b
                    >? A rejection email will be sent with the following reason included in the email.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Reason for Rejection"
                    name="reason"
                    id="reason"
                    v-model="enrollRejectForm.reason"
                    :rules="rules.reason"
                    :error-messages="$error.getValidationError(errors, 'reason')"
                    @input="$error.clearValidationError(errors, 'reason')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="close()">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="reject" :loading="loadingReject" :disabled="!reasonValid">Reject</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import ValidationService from "@/gapp-components/services/validation.service";
import { mapGetters } from "vuex";

export default {
  components: { ApiError },
  name: "RegistrationManagement",
  metaInfo: {
    title: "Registration Management"
  },
  data: function() {
    return {
      loading: false,
      loadingApprove: false,
      loadingReject: false,
      search: "",
      approveDialog: false,
      rejectDialog: false,
      enrollRejectForm: {},
      reasonValid: true,
      headers: [],
      rules: {
        reason: ValidationService.generateTextValidationRules("reason", 255)
      },
      items: [],
      itemSelected: { user: {} },
      errors: {},
      options: {
        itemsPerPage: 10
      },
      total: 0,
      filterByParticipantType: {}
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.fetchData();
    },
    initHeaders() {
      this.headers = [
        { text: "Register Date", value: "createdDate" },
        { text: "Job Title & Location", value: "organization.name" },
        { text: "Registrant", value: "user.firstName" },
        { text: "Approve", value: "approve", sortable: false, align: "center" },
        { text: "Reject", value: "reject", sortable: false, align: "center" }
      ];
    },
    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let searchForm = {};
      if (this.search && this.search.length > 0) {
        searchForm.keyword = this.search;
      }
      searchForm.status = { name: "PRE" };

      let promise = null;
      if (
        this.selectedParticipant.participantType.participantTypeKey != "100" &&
        this.selectedParticipant.participantType.participantTypeKey != "140"
      ) {
        promise = this.$api.post(
          "/api/participants/search/byManaged?status=PRE&size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          {}
        );
      } else {
        promise = this.$api.post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          searchForm
        );
      }
      promise
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
          this.items
            .reduce((p, item) => {
              return p.then(() => this.getRelatedOrganizationData(item));
            }, Promise.resolve())
            .then(() => {
              this.loading = false;
              this.$forceUpdate();
            });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRelatedOrganizationData(item) {
      return this.$api.post("/api/relatedOrganizations/search", { participant: { id: item.id } }).then(({ data }) => {
        item.relatedOrganizations = data.content;
        return Promise.resolve(item);
      });
    },

    close() {
      this.approveDialog = false;
      this.rejectDialog = false;
      this.enrollRejectForm.reason = undefined;
    },

    openApproveDialog(item) {
      this.itemSelected = item;
      this.approveDialog = true;
    },
    reject() {
      this.loadingReject = true;
      let serviceCall = null;
      let postForm = {
        reason: this.enrollRejectForm.reason,
        rejectedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "SALES_REWARDS_REJECTED"
        }
      };
      serviceCall = this.$api.post("/api/participants/reject/" + this.itemSelected.id, postForm);
      serviceCall
        .then(() => {
          this.loadingReject = false;
          this.close();
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    openRejectDialog(item) {
      this.itemSelected = item;
      this.rejectDialog = true;
      this.enrollRejectForm.reason = undefined;
    },
    approve() {
      this.loadingApprove = true;
      let serviceCall = null;
      let postForm = {
        approvedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "SALES_REWARDS_JOIN"
        }
      };
      serviceCall = this.$api.post("/api/participants/approve/" + this.itemSelected.id, postForm);
      serviceCall
        .then(() => {
          this.close();
          this.fetchData();
          this.loadingApprove = false;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    }
  },
  mounted() {
    this.initHeaders();
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Admin" },
      { text: "Registration Management" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedParticipant"])
  }
};
</script>
